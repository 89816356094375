import React from 'react'
import GitHubButton from 'react-github-btn'

function Footer({github}) {
  return (
    <div className="text-center w-full mt-16">
    </div>
  )
}

export default Footer