import React from "react";
import { FaNode, FaReact, FaCode } from "react-icons/fa";

const BOOTSTRAP_FOR_SKILL_ICON = "text-4xl mx-auto inline-block";
const data = {
  name: "Benjamin Lee",
  title: "Full-stack Developer",
  social: {
    github: "https://github.com/CodeWithBenjii",
    email: "benjamin.lee.2021@gmail",
  },
  about: {
    title: "My Background",
    description:
      "Hello! My name is Benjamin and I am a full-stack developer with a special focus on React. I have two years of experience in web development and am passionate about creating dynamic, interactive web applications. I have extensive experience in React, JavaScript, HTML, CSS and Node.js.",
  },
  skills: [
    {
      skillName: "Frontend",
      skillIcon: <FaCode className={BOOTSTRAP_FOR_SKILL_ICON} />,
    },
    {
      skillName: "ReactJS",
      skillIcon: <FaReact className={BOOTSTRAP_FOR_SKILL_ICON} />,
    },
    {
      skillName: "NodeJs",
      skillIcon: <FaNode className={BOOTSTRAP_FOR_SKILL_ICON} />,
    },
    {
      skillName: "NodeJs",
      skillIcon: <FaNode className={BOOTSTRAP_FOR_SKILL_ICON} />,
    },
  ],
  projects: [
    {
      "title": "Three Column Card",
      "link": "https://github.com/CodeWithBenjii/3-column-preview-card-component-gitea",
      "description": "Explore my latest achievement in web development with my Frontend Mentor challenge project. Designed to tackle real-world frontend development challenges, this project showcases my skills in creating responsive and visually appealing web solutions. From pixel-perfect designs to clean and maintainable code, this project exemplifies my dedication to crafting user-friendly experiences.",
      "tags": ["portfolio", "reactjs"]
    },
    {
      "title": "Arch Studio Multi Page Website",
      "link": "https://github.com/CodeWithBenjii/Arch-Studio-Multi-Page-Website",
      "description": "Explore my latest achievement in web development with my Frontend Mentor challenge project. Designed to tackle real-world frontend development challenges, this project showcases my skills in creating responsive and visually appealing web solutions. From pixel-perfect designs to clean and maintainable code, this project exemplifies my dedication to crafting user-friendly experiences.",
      "tags": ["portfolio", "reactjs"]
    },
    {
      "title": "Blogr Landing Page Gitea",
      "link": "https://github.com/CodeWithBenjii/blogr-landing-page-gitea",
      "description": "Explore my latest achievement in web development with my Frontend Mentor challenge project. Designed to tackle real-world frontend development challenges, this project showcases my skills in creating responsive and visually appealing web solutions. From pixel-perfect designs to clean and maintainable code, this project exemplifies my dedication to crafting user-friendly experiences.",
      "tags": ["portfolio", "reactjs"]
    },
    {
      "title": "Devjobs Web App",
      "link": "https://github.com/CodeWithBenjii/Devjobs-web-app",
      "description": "Explore my latest achievement in web development with my Frontend Mentor challenge project. Designed to tackle real-world frontend development challenges, this project showcases my skills in creating responsive and visually appealing web solutions. From pixel-perfect designs to clean and maintainable code, this project exemplifies my dedication to crafting user-friendly experiences.",
      "tags": ["portfolio", "reactjs"]
    },
    {
      "title": "Crowdfunding Product Page",
      "link": "https://github.com/CodeWithBenjii/Crowdfunding-product-page",
      "description": "Explore my latest achievement in web development with my Frontend Mentor challenge project. Designed to tackle real-world frontend development challenges, this project showcases my skills in creating responsive and visually appealing web solutions. From pixel-perfect designs to clean and maintainable code, this project exemplifies my dedication to crafting user-friendly experiences.",
      "tags": ["portfolio", "reactjs"]
    },
    {
      "title": "Bliss SPA Portfolio",
      "link": "https://github.com/CodeWithBenjii/bliss-spa-portfolio",
      "description": "Explore my latest achievement in web development with my Frontend Mentor challenge project. Designed to tackle real-world frontend development challenges, this project showcases my skills in creating responsive and visually appealing web solutions. From pixel-perfect designs to clean and maintainable code, this project exemplifies my dedication to crafting user-friendly experiences.",
      "tags": ["portfolio", "reactjs"]
    },
    {
      "title": "NFT Preview Card Component",
      "link": "https://github.com/CodeWithBenjii/NFT-preview-card-component",
      "description": "Explore my latest achievement in web development with my Frontend Mentor challenge project. Designed to tackle real-world frontend development challenges, this project showcases my skills in creating responsive and visually appealing web solutions. From pixel-perfect designs to clean and maintainable code, this project exemplifies my dedication to crafting user-friendly experiences.",
      "tags": ["portfolio", "reactjs"]
    },
    {
      "title": "Github Dev Finder",
      "link": "https://github.com/CodeWithBenjii/github-dev-finder",
      "description": "Explore my latest achievement in web development with my Frontend Mentor challenge project. Designed to tackle real-world frontend development challenges, this project showcases my skills in creating responsive and visually appealing web solutions. From pixel-perfect designs to clean and maintainable code, this project exemplifies my dedication to crafting user-friendly experiences.",
      "tags": ["portfolio", "reactjs"]
    },
    {
      "title": "Skilled E-Learning Landing Page",
      "link": "https://github.com/CodeWithBenjii/skilled-e-learning-landing-page",
      "description": "Explore my latest achievement in web development with my Frontend Mentor challenge project. Designed to tackle real-world frontend development challenges, this project showcases my skills in creating responsive and visually appealing web solutions. From pixel-perfect designs to clean and maintainable code, this project exemplifies my dedication to crafting user-friendly experiences.",
      "tags": ["portfolio", "reactjs"]
    },
    {
      "title": "Equalizer Landing Page",
      "link": "https://github.com/CodeWithBenjii/equalizer-landing-page",
      "description": "Explore my latest achievement in web development with my Frontend Mentor challenge project. Designed to tackle real-world frontend development challenges, this project showcases my skills in creating responsive and visually appealing web solutions. From pixel-perfect designs to clean and maintainable code, this project exemplifies my dedication to crafting user-friendly experiences.",
      "tags": ["portfolio", "reactjs"]
    }
  ],
};
export default data;
